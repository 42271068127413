<template>
	<section class="container">
		<CompanyAdminList></CompanyAdminList>
		<b-card title="Slet virksomheden" class="mt-3">
			<BaseActionButton class="btn-delete" type="button" v-on:on-click="onSubmit" v-if="$can('delete', 'Company')"> Slet </BaseActionButton>
		</b-card> 
	</section>
</template>

<script>
import Swal from 'sweetalert2'
import CompanyAdminList from '@/modules/general/company/views/CompanyAdminList.vue'

export default {
	name: 'CompanySettings',
	components: { CompanyAdminList },
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			Swal.fire({
				title: 'Slette virksomhed?',
				text: 'Vil du slette virksomheden?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja slet!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						var result = await this.$store.dispatch('companyVuex/deleteCompany', this.companyId)
						return result
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					this.toast('Success', 'Selskabet er blevet slettet', true)
					this.$router.push({ name: 'Companies' })
					this.clicked = false
				}
			})
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		companyName() {
			return this.company?.companyInfo?.name ?? ''
		},
	},
}
</script>

<style></style>
